*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 0, 0.3);
}
 
*::-webkit-scrollbar-thumb {
  background-color: #4848C1;
  border-radius: 5px;
}
